<template>
    <div>
        <div class="page-header">新增邮件模板</div>
        <el-form :model="ruleForm" :rules="rules" ref="formCheck" label-width="100px" class="login-form">
            <div class="block">
                <div class="header">邮件推送模板</div>
                <div class="list">
                    <el-form-item prop="name" label="模板名称：">
                        <el-input v-model="ruleForm.name" placeholder="请输入模板名称" style="max-width:300px;"></el-input>
                    </el-form-item>
                    <el-form-item style="margin-bottom:0px;" prop="content" label="内容：">
                        <div style="height:565px">
                            <quill v-model="ruleForm.content"></quill>
                        </div>
                    </el-form-item>
                </div>
                <div class="flex flex-justify-around" style="width:400px;margin:0 auto;padding-bottom:30px;">
                    <div><el-button @click="back()" style="width: 80px;margin-right:20px;" type="primary">取消</el-button></div>
                    <div><el-button @click="submitForm()" style="width: 80px;" type="primary">确定</el-button></div>
                </div>
            </div>
        </el-form>

    </div>
</template>
<script>
    import localForage from 'localforage';
    import { addMailTpl } from '@/service/crm';
    import quill from '@/components/quill.vue';
    export default {
        components: {
            quill
        },
        data() {
            return {
                popTitle:null,
                popVisible:false,
                rules: {
                    name:[{required: true, message: '请输入模板名称', trigger: 'blur'}],
                    content:[{ required: true, message: '请填写内容', trigger: 'blur' }],
                },
                ruleForm:{
                    name:null,
                    content:null,
                },
            }
        },
        async created() {
            
        },
        mounted() {},
        methods: {
            goto(link) {
                this.$router.push(link);
            },
            back() {
                this.$router.go(-1)
            },
            submitForm(type) {
                this.$refs['formCheck'].validate((valid) => {
                    if (valid) {
                        this.submit();
                    }
                });
            },
            submit() {
                console.log(this.ruleForm);
                addMailTpl(this.ruleForm).then(rst => {
                    this.$router.push("/client/mailTemplate");
                    this.$message.success("添加成功");
                }).catch(err => {
                    this.$message.error(err.message);
                });
            },
        }
    }
</script>
<style scoped src="../style.css"></style>
<style scoped>
    .list {
        padding: 20px;
    }
    .template-title>div:first-child {
        width:110px;
        padding:0 10px 20px 0;text-align:right;
    }
    .template-item {
        max-width: 300px;
    }
</style>